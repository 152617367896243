<template>
  <div class="mainContent grid justify-items-center text-center">
    <div class="flex flex-col justify-center items-center pt-8">
      <img :src="logo" class="w-2/3" />
      <div class="text-black text-5xl text-center mt-2">
        <span class="font-ZuumeExtraBold business-name" ref="businessNameRef">
          {{ businessName }}
        </span>
        <div class="text-black text-base font-ZuumeSemiBold">
          {{ businessLocation }}
        </div>
      </div>
    </div>
    <img
      :src="require('@/assets/images/congratulations-text.svg')"
      class="w-4/6"
    />
    <div
      class="relative font-InterMedium mx-8 text-sm mt-2 border border-nColorDarkGray px-4 py-2 rounded-xl shadow-black-left"
    >
      <img
        :src="require('@/assets/images/instagram.svg')"
        class="absolute -top-5 left-1/2 transform -translate-x-1/2"
      />
      <div class="my-4">
        Follow us on Instagram to get updates on new games, new locations & new
        freebies.
      </div>
      <div
        class="scaling-element px-2 rounded-2xl bg-nColorOrange shadow-black-left border border-black uppercase font-ZuumeRegular text-2xl absolute -bottom-5"
        @click.prevent="openInstagram"
      >
        Follow
      </div>
    </div>
    <div class="absolute w-full bottom-12">
      <img
        :src="require('@/assets/images/congratulations.png')"
        class="w-4/6 absolute bottom-10 left-1/2 transform -translate-x-1/2"
      />
      <div class="w-full absolute bottom-0">
        <div class="flex justify-center">
          <button
            @click.prevent="goBackToLobby()"
            class="shadow-border-orange-right bg-black rounded-3xl w-5/6 text-white text-center py-1 uppercase text-3xl font-ZuumeSemiBold tracking-wider focus:outline-none"
          >
            Next game
          </button>
        </div>
      </div>
    </div>
    <Copyright :absoluteBottom="'1'" />
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import Copyright from "@/components/elements/Copyright.vue";
import slugs from "@/common/slugs";

export default {
  name: "Congratulations",
  components: { Copyright },
  data() {
    return {
      businessName: this.$store.getters.businessName,
      businessLocation: this.$store.getters.businessLocation,
      logo: this.$store.getters.businessLogo,
      slugs,
    };
  },
  created() {
    ApiService.post(apiResource.claimFreebie.validateCongratsStatus, {
      business_slug: this.$route.params.business,
      schedule_id: this.$route.params.schedule_id,
    })
      .then(() => {})
      .catch(() => {
        this.goBackToLobby();
      });
  },
  mounted() {
    this.resizeText();
  },
  methods: {
    goBackToLobby() {
      this.$router.push({
        name: "Lobby",
        params: {
          business: this.$route.params.business,
        },
      });
    },
    resizeText() {
      const businessNameElement = this.$refs.businessNameRef;
      const containerWidth = businessNameElement.clientWidth;
      const originalText = this.businessName;
      let fontSize = 30; // Default font size
      const maxFontSize = 50; // Maximum font size

      // Create a temporary element to measure the width of the text
      const tempElement = document.createElement("span");
      tempElement.style.visibility = "hidden";
      tempElement.style.fontSize = fontSize + "px";
      tempElement.textContent = originalText;
      document.body.appendChild(tempElement);

      // Reduce font size until it fits within the container width
      while (tempElement.clientWidth > containerWidth && fontSize > 0) {
        fontSize--;
        tempElement.style.fontSize = fontSize + "px";
      }

      // Remove the temporary element
      document.body.removeChild(tempElement);

      // Set the final font size for the business name element
      businessNameElement.style.fontSize =
        Math.min(fontSize, maxFontSize) + "px";
    },
    openInstagram() {
      if (this.isInstagramAppInstalled()) {
        // Instagram app is installed, open the app using the custom URL scheme
        const instagramURL = "instagram://user?username=letsplayda";
        window.location.href = instagramURL;
      } else {
        // Instagram app is not installed, open instagram.com in a new tab
        const instagramURL = "https://www.instagram.com/letsplayda/";
        window.open(instagramURL, "_blank");
      }
    },
    isInstagramAppInstalled() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Check for the Instagram app package name (Android) or bundle identifier (iOS)
      return userAgent.match(/instagram/i) !== null;
    },
  },
};
</script>

<style scoped lang="postcss">
.congratulations {
  height: 100dvh;
}
.blurry-div {
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.5);
}
.shadowOrange {
  box-shadow: 4px 4px 0px rgba(246, 112, 52, 0.8);
}
.scaling-element {
  /* Your existing styles go here */

  /* Add scaling animation */
  animation: scaleAnimation 0.5s infinite alternate;
  left: 39%;
  transform: translateX(-50%);
}

/* Define the @keyframes for the scaling animation */
@keyframes scaleAnimation {
  0% {
    transform: scale(1); /* Initial scale, 100% */
  }
  100% {
    transform: scale(1.2); /* Scaled up to 120% */
  }
}
</style>
